jQuery(function ($) {
  $(document).ready(function () {
    $(".slider").slick({
      autoplay: true,
      autoplaySpeed: 0,
      speed: 50000,
      arrows: false,
      swipe: false,
      slidesToShow: 1,
      cssEase: "linear",
      pauseOnFocus: false,
      pauseOnHover: false
    });

    var a = 0;
    $(window).scroll(function () {
      var oTop = $("#counter-box").offset().top - window.innerHeight;
      if (a == 0 && $(window).scrollTop() > oTop) {
        $(".counter").each(function () {
          var $this = $(this),
            countTo = $this.attr("data-number");
          $({
            countNum: $this.text()
          }).animate(
            {
              countNum: countTo
            },
            {
              duration: 5000,
              easing: "swing",
              step: function () {
                //$this.text(Math.ceil(this.countNum));
                $this.text(Math.ceil(this.countNum).toLocaleString("en"));
              },
              complete: function () {
                $this.text(Math.ceil(this.countNum).toLocaleString("en"));
                //alert('finished');
              }
            }
          );
        });
        a = 1;
      }
    });

    $(".network-slider").owlCarousel({
      loop: true,
      autoplay: true,
      autoplayTimeout:1000,
      margin: 10,
      nav: false,
      dots: false,
      responsive: {
        0: {
          items: 2,
          touchDrag: true
        },
        600: {
          items: 4
        },
        1000: {
          items: 5
        }
      }
    });

    $("#client-mobile-slider-left").owlCarousel({
      loop: true,
      margin: 0,
      nav: false,
      dots: false,
      responsiveClass: true,
      autoplay: true,
      autoplayTimeout: 5000,
      autoplayHoverPause: true,
      responsive: {
        0: {
          dotsEach: 3,
          items: 2
        },
        600: {
          items: 2
        },
        1000: {
          items: 1
        }
      }
    });

    $("#client-mobile-slider-right").owlCarousel({
      loop: true,
      rtl: true,
      margin: 0,
      nav: false,
      dots: false,
      responsiveClass: true,
      autoplay: true,
      autoplayTimeout: 5000,
      autoplayHoverPause: true,
      responsive: {
        0: {
          dotsEach: 3,
          items: 2
        },
        600: {
          items: 2
        },
        1000: {
          items: 1
        }
      }
    });

    $(
      ".osc-1, .osc-3, .osc-4, .osc-5, .osc-6, .osc-7, .osc-8, .osc-9, .osc-10, .osc-11, .osc-12, .osc-13, .osc-14, .osc-15, .osc-16, .osc-17"
    ).css("display", "none");

    $("#os_3").addClass("test-hilight");

    $("#os_3").click(() => {
      $(".osc-1, .osc-3, .osc-4, .osc-5, .osc-6, .osc-7, .osc-8, .osc-9, .osc-10, .osc-11, .osc-12, .osc-13, .osc-14, .osc-15, .osc-16, .osc-17").css("display", "none");
      $("#os_1, #os_2, #os_4, #os_5, #os_6, #os_7, #os_8, #os_9, #os_10, #os_11, #os_12, #os_13, #os_14, #os_15, #os_16, #os_17").removeClass("test-hilight");
      $('.osc-2').fadeIn(2000);
      $("#os_3").addClass("test-hilight");
    });

    $("#os_5").click(() => {
      $(".osc-1, .osc-2, .osc-4, .osc-5, .osc-6, .osc-7, .osc-8, .osc-9, .osc-10, .osc-11, .osc-12, .osc-13, .osc-14, .osc-15, .osc-16, .osc-17").css("display", "none");
      $("#os_1, #os_2, #os_3, #os_4, #os_6, #os_7, #os_8, #os_9, #os_10, #os_11, #os_12, #os_13, #os_14, #os_15, #os_16, #os_17").removeClass("test-hilight");
      $('.osc-3').fadeIn(2000);
      $('#os_5').addClass('test-hilight');
    });

    $("#os_1").click(() => {
      $(".osc-2, .osc-3, .osc-4, .osc-5, .osc-6, .osc-7, .osc-8, .osc-9, .osc-10, .osc-11, .osc-12, .osc-13, .osc-14, .osc-15, .osc-16, .osc-17").css("display", "none");
      $("#os_2, #os_3, #os_4, #os_5, #os_6, #os_7, #os_8, #os_9, #os_10, #os_11, #os_12, #os_13, #os_14, #os_15, #os_16, #os_17").removeClass("test-hilight");
      $('.osc-1').fadeIn(2000);
      $('#os_1').addClass('test-hilight');
    });

    $("#os_2").click(() => {
      $(".osc-1, .osc-2, .osc-3, .osc-5, .osc-6, .osc-7, .osc-8, .osc-9, .osc-10, .osc-11, .osc-12, .osc-13, .osc-14, .osc-15, .osc-16, .osc-17").css("display", "none");
      $("#os_1, #os_3, #os_4, #os_5, #os_6, #os_7, #os_8, #os_9, #os_10, #os_11, #os_12, #os_13, #os_14, #os_15, #os_16, #os_17").removeClass("test-hilight");
      $('.osc-4').fadeIn(2000);
      $('#os_2').addClass('test-hilight');
    });

    $("#os_12").click(() => {
      $(".osc-1, .osc-2, .osc-3, .osc-4, .osc-6, .osc-7, .osc-8, .osc-9, .osc-10, .osc-11, .osc-12, .osc-13, .osc-14, .osc-15, .osc-16, .osc-17").css("display", "none");
      $("#os_1, #os_2, #os_3, #os_4, #os_5, #os_6, #os_7, #os_8, #os_9, #os_10, #os_11, #os_13, #os_14, #os_15, #os_16, #os_17").removeClass("test-hilight");
      $('.osc-5').fadeIn(2000);
      $('#os_12').addClass('test-hilight');
    });

    $("#os_13").click(() => {
      $(".osc-1, .osc-2, .osc-3, .osc-4, .osc-5, .osc-7, .osc-8, .osc-9, .osc-10, .osc-11, .osc-12, .osc-13, .osc-14, .osc-15, .osc-16, .osc-17").css("display", "none");
      $("#os_1, #os_2, #os_3, #os_4, #os_5, #os_6, #os_7, #os_8, #os_9, #os_10, #os_11, #os_12, #os_14, #os_15, #os_16, #os_17").removeClass("test-hilight");
      $('.osc-6').fadeIn(2000);
      $('#os_13').addClass('test-hilight');
    });

    $("#os_11").click(() => {
      $(".osc-1, .osc-2, .osc-3, .osc-4, .osc-5, .osc-6, .osc-8, .osc-9, .osc-10, .osc-11, .osc-12, .osc-13, .osc-14, .osc-15, .osc-16, .osc-17").css("display", "none");
      $("#os_1, #os_2, #os_3, #os_4, #os_5, #os_6, #os_7, #os_8, #os_9, #os_10, #os_12, #os_13, #os_14, #os_15, #os_16, #os_17").removeClass("test-hilight");
      $('.osc-7').fadeIn(2000);
      $('#os_11').addClass('test-hilight');
    });

    $("#os_14").click(() => {
      $(".osc-1, .osc-2, .osc-3, .osc-4, .osc-5, .osc-6, .osc-7, .osc-9, .osc-10, .osc-11, .osc-12, .osc-13, .osc-14, .osc-15, .osc-16, .osc-17").css("display", "none");
      $("#os_1, #os_2, #os_3, #os_4, #os_5, #os_6, #os_7, #os_8, #os_9, #os_10, #os_11, #os_12, #os_13, #os_15, #os_16, #os_17").removeClass("test-hilight");
      $('.osc-8').fadeIn(2000);
      $('#os_14').addClass('test-hilight');
    });

    $("#os_15").click(() => {
      $(".osc-1, .osc-2, .osc-3, .osc-4, .osc-5, .osc-6, .osc-7, .osc-8, .osc-10, .osc-11, .osc-12, .osc-13, .osc-14, .osc-15, .osc-16, .osc-17").css("display", "none");
      $("#os_1, #os_2, #os_3, #os_4, #os_5, #os_6, #os_7, #os_8, #os_9, #os_10, #os_11, #os_12, #os_13, #os_14, #os_16, #os_17").removeClass("test-hilight");
      $('.osc-9').fadeIn(2000);
      $('#os_15').addClass('test-hilight');
    });

    $("#os_6").click(() => {
      $(".osc-1, .osc-2, .osc-3, .osc-4, .osc-5, .osc-6, .osc-7, .osc-8, .osc-9, .osc-11, .osc-12, .osc-13, .osc-14, .osc-15, .osc-16, .osc-17").css("display", "none");
      $("#os_1, #os_2, #os_3, #os_4, #os_5, #os_7, #os_8, #os_9, #os_10, #os_11, #os_12, #os_13, #os_14, #os_15, #os_16, #os_17").removeClass("test-hilight");
      $('.osc-10').fadeIn(2000);
      $('#os_6').addClass('test-hilight');
    });

    $("#os_17").click(() => {
      $(".osc-1, .osc-2, .osc-3, .osc-4, .osc-5, .osc-6, .osc-7, .osc-8, .osc-9, .osc-10, .osc-12, .osc-13, .osc-14, .osc-15, .osc-16, .osc-17").css("display", "none");
      $("#os_1, #os_2, #os_3, #os_4, #os_5, #os_6, #os_8, #os_9, #os_10, #os_11, #os_12, #os_13, #os_14, #os_15, #os_16").removeClass("test-hilight");
      $('.osc-11').fadeIn(2000);
      $('#os_17').addClass('test-hilight');
    });

    $("#os_16").click(() => {
      $(".osc-1, .osc-2, .osc-3, .osc-4, .osc-5, .osc-6, .osc-7, .osc-8, .osc-9, .osc-10, .osc-11, .osc-13, .osc-14, .osc-15, .osc-16, .osc-17").css("display", "none");
      $("#os_1, #os_2, #os_3, #os_4, #os_5, #os_6, #os_7, #os_8, #os_9, #os_10, #os_11, #os_12, #os_13, #os_14, #os_15, #os_17").removeClass("test-hilight");
      $('.osc-12').fadeIn(2000);
      $('#os_16').addClass('test-hilight');
    });

    $("#os_7").click(() => {
      $(".osc-1, .osc-2, .osc-3, .osc-4, .osc-5, .osc-6, .osc-7, .osc-8, .osc-9, .osc-10, .osc-11, .osc-12, .osc-14, .osc-15, .osc-16, .osc-17").css("display", "none");
      $("#os_1, #os_2, #os_3, #os_4, #os_5, #os_6, #os_8, #os_9, #os_10, #os_11, #os_12, #os_13, #os_14, #os_15, #os_16, #os_17").removeClass("test-hilight");
      $('.osc-13').fadeIn(2000);
      $('#os_7').addClass('test-hilight');
    });

    $("#os_8").click(() => {
      $(".osc-1, .osc-2, .osc-3, .osc-4, .osc-5, .osc-6, .osc-7, .osc-8, .osc-9, .osc-10, .osc-11, .osc-12, .osc-13, .osc-15, .osc-16, .osc-17").css("display", "none");
      $("#os_1, #os_2, #os_3, #os_4, #os_5, #os_6, #os_7, #os_9, #os_10, #os_11, #os_12, #os_13, #os_14, #os_15, #os_16, #os_17").removeClass("test-hilight");
      $('.osc-14').fadeIn(2000);
      $('#os_8').addClass('test-hilight');
    });

    $("#os_9").click(() => {
      $(".osc-1, .osc-2, .osc-3, .osc-4, .osc-5, .osc-6, .osc-7, .osc-8, .osc-9, .osc-10, .osc-11, .osc-12, .osc-13, .osc-14, .osc-16, .osc-17").css("display", "none");
      $("#os_1, #os_2, #os_3, #os_4, #os_5, #os_6, #os_7, #os_8, #os_10, #os_11, #os_12, #os_13, #os_14, #os_15, #os_16, #os_17").removeClass("test-hilight");
      $('.osc-15').fadeIn(2000);
      $('#os_9').addClass('test-hilight');
    });

    $("#os_10").click(() => {
      $(".osc-1, .osc-2, .osc-3, .osc-4, .osc-5, .osc-6, .osc-7, .osc-8, .osc-9, .osc-10, .osc-11, .osc-12, .osc-13, .osc-14, .osc-15, .osc-17").css("display", "none");
      $("#os_1, #os_2, #os_3, #os_4, #os_5, #os_6, #os_7, #os_8, #os_9, #os_11, #os_12, #os_13, #os_14, #os_15, #os_16, #os_17").removeClass("test-hilight");
      $('.osc-16').fadeIn(2000);
      $('#os_10').addClass('test-hilight');
    });

    $("#os_4").click(() => {
      $(".osc-1, .osc-2, .osc-3, .osc-4, .osc-5, .osc-6, .osc-7, .osc-8, .osc-9, .osc-10, .osc-11, .osc-12, .osc-13, .osc-14, .osc-15, .osc-16").css("display", "none");
      $("#os_1, #os_2, #os_3, #os_5, #os_6, #os_7, #os_8, #os_9, #os_10, #os_11, #os_12, #os_13, #os_14, #os_15, #os_16, #os_17").removeClass("test-hilight");
      $('.osc-17').fadeIn(2000);
      $('#os_4').addClass('test-hilight');
    });

    $('.our-solution').owlCarousel({
      loop:true,
      margin:10,
      nav:false,
      responsive:{
          0:{
              items:1
          },
          600:{
              items:1
          },
          1000:{
              items:5
          }
      }
  })

  });
});
